import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../css/pages/second-chance.scss"
import ImageSlider from "../components/ImageSlider/ImageSlider"

const SecondChanceProgram = () => {
  return (
    <Layout>
      <SEO
        title="Second Chance Program"
        description="It is time for a brand new, healthy, beautiful smile at Innovative Oral Surgery & Dental Implant Studio."
      />

      <div className="sas">
        <div className="sas__hero sas__section">
          <img
            className="sas__hero-img--desktop"
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/second-chance-desktop-hero-image"
            alt="second chance banner"
          />
          <img
            className="sas__hero-img--mobile"
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/second-chance-mobile-hero-image"
            alt="second chance banner"
          />
        </div>

        <div className="sas__body sas__section">
          <div className="sas__container small">
            <h1>An Opportunity for a Second Chance to Smile</h1>

            <p>
              Innovative Oral Surgery & Dental Implant Studio is proud to
              announce its 2023 Second Chance recipient, Audrey. Each year, our
              Second Chance program will provide one person who has many broken,
              missing, and/or decayed teeth who lives or works in the Louisville
              or Bardstown area with a complimentary full-arch restoration{" "}
              <Link
                to="/full-arch-restoration/"
                title="Learn more about full-arch-restoration">
                full-arch restoration
              </Link>{" "}
              procedure.
            </p>

            <p>
              Audrey is a 64-year old retired Air Force veteran. In her
              application, she explained the rapid decline of her dental health
              over the past 18 months. She has many missing teeth, and those
              that are left are chipped and decayed. She went on to say that
              restoring her smile is extremely important to her because she
              wants to love life and live again, and also, “I co-founded a
              nonprofit and would love to spend more time mentoring women and
              fundraising for our scholarship funds.”
            </p>
            <p>
              Her ultimate goal is to obtain her doctorate so she can develop
              educational programs in mentoring, leadership, and instructional
              strategies. In her application, she said, “I love helping students
              and young adults reach career and personal goals.”{" "}
            </p>
            <p>
              We are proud to be able to give back to Audrey, someone who is so
              passionate about helping others. We know that providing her with a
              healthy smile is one step toward helping her regain her
              self-confidence as well as her ability to eat and maintain her
              overall health.
            </p>

            <h3>Full-Arch Restoration</h3>

            <p>
              Audrey will receive a complete set of new teeth at no cost. This
              type of tooth replacement is called full-arch restoration, and it
              involves using dental implants to secure a fixed denture in one or
              both arches. Our experts will work closely with a restorative
              dentist to complete the treatment.
            </p>
          </div>
        </div>

        <div className="sas__section">
          <div className="sas__container">
            <div className="sas__brackets">
              <div className="sas__about-grid">
                <div>
                  <p className="sas__subheading">Treatment Partners:</p>
                  <ul>
                    <li>
                      Restorative Partner: Dr. David Watkins - Watkins Family
                      Dental
                    </li>
                    <li>Dental Lab: ADL</li>
                    <li>Implant Provider: Straumann</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sas__section">
          <div className="sas__container">
            <ImageSlider
              useDots
              adaptiveHeight
              images={[
                "ELOS/DEV/second-chance-recipient-2023-1",
                "ELOS/DEV/second-chance-recipient-2023-2",
                "ELOS/DEV/second-chance-recipient-2023-3",
                "ELOS/DEV/second-chance-recipient-2023-4"
              ]}
            />
          </div>
        </div>

        <div className="sas__section">
          <div className="sas__container small">
            <p>
              Follow this page,{" "}
              <a
                href="https://www.facebook.com/eastlouisvilleoralsurgery"
                title="Follow us on Facebook"
                target="_blank">
                Facebook
              </a>
              , and{" "}
              <a
                href="https://www.instagram.com/eastlouisville_oralsurgery/"
                title="Follow us on Instagram"
                target="_blank">
                Instagram
              </a>{" "}
              for more program updates!
            </p>

            <p>
              <i>
                Learn more about{" "}
                <Link
                  to="/dental-implants-louisville-ky/"
                  title="Learn more about dental implants">
                  dental implants
                </Link>{" "}
                and{" "}
                <Link
                  to="/full-arch-restoration/"
                  title="Learn more about full-arch-restoration">
                  full-arch restoration
                </Link>{" "}
                to see how they can benefit your smile. Whether you need to
                replace one tooth or an entire arch of teeth, our team has a
                solution for you.
              </i>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SecondChanceProgram
